html, body {
	padding: 0;
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	min-width: 380px;
}

img {
	max-width: 100%;
}

hr {
	height:1px;
	border: 0;
	background-color: #ddd;
	margin-bottom: 50px;
}

h1 {
	padding: 0;
	margin: 0 0 20px 0;
	font-size: 3em;
}

h2 {
	font-size: 2.3em;
	margin: 0 0 30px 0;
	text-align: center;
}

h3 {
	font-size: 1.8em;
	margin: 20px 0 20px 0;
}

p {
	font-size: 1.3em;
	line-height: 1.4em;
	color: #888;
}

nav {
	padding: 30px 0;

	svg {
		fill: white;
		stroke: $brand-color;
		height: $navbar-height;

		text {
			fill: $brand-color;
		}
	}
}

section {
	padding: 60px 0;
}

form, .contact-description {
	display: grid;
	max-width: 500px;
	margin: 0 auto;
}

form {
	input[type="text"], textarea {
		// flex-grow: 1;
		// flex-shrink: 1;
		margin: 10px;
		border: 1px solid #ddd;


		&, &:focus {
			border-radius: 7px;
			border-radius: 7px;
		}

		&:focus {
			box-shadow: 0 0 5px 0 $brand-color;
			outline: none;
		}
	}

	textarea {
		height: 200px;
	}

	input[type="submit"] {
		border-radius: 30px;
		border-radius: 30px;

		&:hover {
			background: darken($brand-color, 5%);
		}
	}
}

.button a, input[type="submit"], input[type="text"] {
	border: 0;
	padding: 15px 40px;
	box-sizing: content-box;
	font-size: 1em;
	font-weight: 100;
}

input[type="text"], textarea {
	padding: 15px 20px;
	font-size: 1em;
}

.button a, input[type="submit"] {
	background: $brand-color;
	color: #fff;
	cursor: pointer;
	font-weight: bold;
}

.button a {
	display: inline-block;
	text-decoration: none;
	border-radius: 30px;
	transition: 200ms ease-in background;

	&:hover {
		background: darken($brand-color, 5%);
	}
}

.contact.button {
	width: 100px;
	margin: 0 auto !important;
}

a.logo {
	text-decoration: none;
}

.price {
	color: $brand-color;
	background-color: #f9f9f9;
	padding: 5px;
	// border-radius: 7px;
	font-weight: bold;
	font-size: 1.5em;
}

.no-stock {
	color: red;
}